function createObject() {
    let example = {
        id: 'Einzeldiagnose_Taifun',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie würde Taifun die nächste Aufgabe lösen?",
                    answers: [{
                            correct: false,
                            text: "Möglichkeit 1",
                            //additionalExplanation: "",
                            response: `Das halten wir für unwahrscheinlich. In dieser Lösung wurde der Tausenderwürfel als Hunderter interpretiert (die 2 steht in der Hunderterspalte statt in der Tausenderspalte). In Taifuns anderen Lösungen gibt es keine Anhaltspunkte dafür, dass er die Symbole für die Stellenwerte nicht kennt.`,
                            //additionalExplanationResponse: "",
                            image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun_A.png"),
                        },
                        {
                            correct: true,
                            text: "Möglichkeit 2",
                            response: `Das denken wir auch. Taifun würde vermutlich die Lösung 20358 aufschreiben, da er in allen drei vorherigen Aufgaben zunächst die Anzahl der Tausender, Hunderter, Zehner und Einer aus der bildlichen Darstellung in Stellentafel eingetragen hat (auch zweistellige Anzahlen) und dann die Ziffern aus der Stellentafel hintereinander aufgeschrieben hat, um die Zahl zu erhalten.`,
                            image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun_B.png"),
                        },
                        {
                            correct: false,
                            text: "Möglichkeit 3",
                            response: `Hier wurde in der Zahlschreibweise die Null nicht notiert (2358 statt 20358). In den anderen Lösungen von Taifun werden die Nullen in der Zahl jedoch immer geschrieben. Deshalb erscheint diese Lösung unwahrscheinlich.`,
                            image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Taifun_C.png"),
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Welche der folgenden Kompetenzen scheint Taifun auf Grund des vorliegenden Dokumentes bereits zu haben?",
                    answers: [{
                            correct: false,
                            text: "Taifun kann Zahlen bildlich darstellen.",
                            //additionalExplanation: "",
                            response: `Hierrüber kann man keine Aussage treffen, da die bildliche Darstellung schon vorgegeben ist, Taifun sie also nicht selbst angefertigt hat.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Taifun kann die Anzahl der Tausender, Hunderter, Zehner und Einer aus der bildlichen Darstellung korrekt in eine formale Darstellung in der Stellentafel übertragen.",
                            response: `Das denken wir auch, denn Taifun überträgt  in den gegebenen Aufgaben die bildliche Darstellung korrekt in die Stellentafel. Dass er hier noch keine Bündelung vornimmt, ist kein Fehler (68 in die Einerspalte einzutragen ist genauso richtig wie 6 in die Zehnerspalte und 8 in die Einerspalte einzutragen).`,
                        },
                        {
                            correct: false,
                            text: "Taifun kann Zahlen in Ziffernschreibweise korrekt in die Stellentafel übertragen.",
                            response: `Es ist schwierig hierzu eine Aussage zu treffen, denn es ist naheliegend, dass Taifun zunächst die bildliche Darstellung in die Stellentafel übersetzt und erst dann in die Ziffernschreibweise überführt. Sollte er zuerst die Zahl in Ziffernschreibweise geschrieben haben (was wir für unwahrscheinlich halten), so wäre die anschließende Übertragung der Zahlen aus der Ziffernschreibweise in die Stellentafel jedoch nicht korrekt.`,
                        },
                    ]
                },
                {
                    question: "Welche Vermutung über die Ursachen des Fehlers können Sie anhand des Dokuments machen?",
                    answers: [{
                            correct: true,
                            text: "Taifun hat das Prinzip des Bündelns und das Prinzip des Stellenwerts noch nicht verstanden.",
                            //additionalExplanation: "",
                            response: `Das denken wir auch. Da Taifun bei mehr als 9 Elementen eines Stellenwerts die zweistellige Anzahl der Elemente in der Ziffernschreibweise der Zahl an eine Stelle schreibt, hat er das Prinzip des Bündelns und das Prinzip des Stellenwerts nicht richtig verstanden (er bündelt nicht und schreibt zwei Ziffer quasi an eine Stelle).`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Taifun hat Probleme beim Darstellungswechsel von der bildlichen Darstellung in die Stellentafel.",
                            response: `Das glauben wir nicht, denn Taifun gelingt es in den vorliegenden Aufgaben die bildliche Darstellung korrekt in die Stellentafel zu übertragen (die erste Lösung, welche wir in der ersten Frage vorschlagen wäre ein Beispiel für dieses Problem).`,
                        },
                        {
                            correct: false,
                            text: "Taifun hat die Bedeutung der Null in einem Stellenwertsystem noch nicht verstanden.",
                            response: `Da Taifun sowohl in der Stellentafel als auch in der Zahlschreibweise immer eine Null notiert, wenn von einem Stellenwert keine Elemente vorhanden sind, glauben wir nicht, dass er hiermit Probleme hat (die dritte Lösung, welche wir in der ersten Frage vorschlagen wäre ein Beispiel für dieses Problem).`,
                        },
                    ]
                }
            ],
        ]
    }

    /*
    *   all correct response
   Sehr gut, Marie kann schon gut einstellige Zahlen zu zweistelligen Zahlen addieren.Allerdings hat sie noch Probleme das Prinzip des Stellenwerts zu verstehen.
    */

    return example;
}

export default createObject();