function createObject() {
    let example = {
        id: 'Einzeldiagnose_Marie',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Marie.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie würde Marie die nächste Aufgabe vermutlich lösen?",
                    answers: [{
                            correct: false,
                            text: "58 + 21 = 37",
                            //additionalExplanation: "",
                            response: `Diese Lösung halten wir für unwahrscheinlich. Hier wurde die jeweils kleinere von der größeren Zahl subtrahiert (58 - 21). Dies entspricht nicht dem Fehlermuster aus den ersten drei Aufgaben, die Marie gelöst hat.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "58 + 21 = 16",
                            response: `Es ist unwahrscheinlich, dass Marie die Aufgabe so löst. In dieser Lösung wurden jeweils alle vorhandenen Ziffern addiert (5 + 8 + 2 + 1). Dies entspricht nicht Maries Fehlermuster.`,
                        },
                        {
                            correct: true,
                            text: "58 + 21 = 61",
                            response: `Das glauben wir auch. Marie addiert in den ersten drei Aufgaben die beiden Ziffern des zweiten Summanden schrittweise zum ersten Summanden und würde mit dieser Vorgehensweise bei dieser Aufgabe vermutlich das Ergebnis 61 (58 + 2 + 1) erhalten.`,
                            //cols: 4
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Über welche der folgenden Kompetenzen scheint Marie aufgrund des vorliegenden Dokuments bereits zu verfügen?",
                    answers: [{
                            correct: false,
                            text: "Marie kann die stellenweise Addition von zweistelligen Zahlen korrekt durchführen, solange keine Bündelung erforderlich ist.",
                            //additionalExplanation: "",
                            response: `Das glauben wir nicht, denn Marie kommt bei den vorliegenden Aufgaben auch nicht zu richtigen Ergebnissen wenn keine Bündelung bei den Einern erforderlich ist.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Marie kann einstellige Zahlen korrekt zu zweistelligen Zahlen addieren.",
                            response: `Das denken wir auch. Wären die zweiten Summanden jeweils als Ziffern zu betrachten, würde Marie diese korrekt zum ersten Summanden addieren. Zum Beispiel 43 + 1 + 2 = 46.`,
                        },
                        {
                            correct: false,
                            text: "Marie kann zwei einstellige Zahlen korrekt addieren.",
                            response: `Marie addiert bei ihrer Vorgehensweise immer nur einstellige Zahlen zu zweistelligen Zahlen. Da ihr dies fehlerfrei gelingt, kann man zwar vermuten, dass ihr auch das addieren zweier einstelliger Zahlen fehlerfrei gelingen wird, man kann hierüber jedoch keine sichere Aussage treffen.`,
                        },
                    ]
                },
                {
                    question: "Welche Vermutung über die Ursache des Fehlers können Sie anhand des Dokuments machen?",
                    answers: [{
                            correct: false,
                            text: "Marie hat das Prinzip des Bündelns noch nicht verstanden.",
                            //additionalExplanation: "",
                            response: `Das glauben wir nicht. Bei der Addition einstelliger Zahlen zu zweistelligen Zahlen bündelt Marie - sofern erforderlich – korrekt, was darauf hindeutet, dass ihr das Bündelungsprinzip sehr wohl bekannt ist. Dennoch bedarf es einer weiteren Feindiagnostik, um diese Aussage zu bestätigen.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Marie fehlt eine Grundvorstellung zur Addition.",
                            response: `Hierüber kann man keine genaue Aussage treffen, da man anhand der Aufgaben nichts über ihre Vorstellung zur Addition erfährt. Über Aufgaben, anhand derer Sie etwas über die Grundvorstellung von Kindern erfahren, können Sie sich beim Thema Operationsverständnis informieren.`,
                        },
                        {
                            correct: true,
                            text: "Marie hat das Prinzip des Stellenwerts noch nicht verstanden.",
                            response: `Das denken wir auch. Bei der Addition der Ziffern des zweiten Summanden zum ersten Summanden lässt Marie die Stellenwerte des zweiten Summanden außer Acht (58 + 2 + 1). Dies deutet auf Probleme im Verständnis des Prinzip des Stellenwerts hin.`,
                        },
                    ]
                }
            ],
        ]
    }

    /*
    *   all correct response
   Sehr gut, Marie kann schon gut einstellige Zahlen zu zweistelligen Zahlen addieren.Allerdings hat sie noch Probleme das Prinzip des Stellenwerts zu verstehen.
    */

    return example;
}

export default createObject();