function createObject() {
    let example = {
        id: 'Einzeldiagnose_Clara',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie würde Clara die nächste Aufgabe vermutlich lösen?",
                    answers: [{
                            correct: false,
                            text: "29 + 6 = 89",
                            //additionalExplanation: "",
                            response: `Diese Lösung halten wir für unwahrscheinlich, da die beiden Summanden nicht stellengerecht addiert werden. Entweder wurden hier die 2 Zehnern des ersten Summanden mit den 6 Einer des zweiten Summanden verrechnet (2 + 6 = 8). Oder es wurde zunächst 92 + 6 = 98 gerechnet und das Ergebnis dann falschrum aufgeschrieben worden sein (89 statt 98). Beide Fehler treten in anderen von Clara gelösten Aufgaben jedoch nicht auf.`,
                            //additionalExplanationResponse: "",
                            //cols: 4
                        },
                        {
                            correct: false,
                            text: "29 + 6 = 296",
                            response: `Das halten wir für unwahrscheinlich. In dieser Lösung werden alle Ziffern der Aufgabe in der Reihenfolge, in der sie in der Aufgabe auftreten, hintereinander geschrieben. Das entspricht nicht Claras Fehlermuster aus den vorherigen Aufgaben.`,
                            //cols: 4
                        },
                        {
                            correct: true,
                            text: "29 + 6 = 215",
                            response: `Das denken wir auch. Clara addiert die beiden Summanden stellenweise, das heißt Zehner und Einer separat (2 + 0 = 2 und 9 + 6 = 15) und schreibt die beiden Ergebnisse hintereinander auf. Wenn bei der Addition der beiden Einer mehr als 9 entstehen, notiert sie das zweistellige Ergebnis an der Einerstelle (quasi zwei Ziffern an eine Stelle). Die Anzahl der Zehner schreibt sie links daneben.`,
                            //cols: 4
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Über welche der folgenden Kompetenzen scheint Clara aufgrund des vorliegenden Dokuments bereits zu verfügen?",
                    answers: [{
                            correct: true,
                            text: "Clara kann die stellenweise Addition von ein- und zweistelligen Zahlen zu zweistelligen Zahlen korrekt durchführen, solange keine Bündelung erforderlich ist.",
                            //additionalExplanation: "",
                            response: `Das denken wir auch. Solange keine Bündelung erforderlich ist, gelingt es Clara die vorliegenden Additionsaufgaben durch stellenweises Rechnen zu lösen.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Clara kann zwei zweistellige Zahlen korrekt addieren.",
                            response: `Das glauben wir nicht. Clara verwendet in den vorliegenden Aufgaben ein Verfahren zu Addition zweistelliger Ziffern, mit dem sie nicht zum korrekten Ergebnis kommt, sobald das Bündeln erforderlich wird.`,
                        },
                        {
                            correct: false,
                            text: "Clara kann die Addition einer zweistelligen Zahl und einer einstelligen Zahl korrekt durchführen.",
                            response: `Das glauben wir nicht, denn das gelingt Clara in den vorliegenden Aufgaben nur, solange keine Bündelung erforderlich ist.`,
                        },
                    ]
                },
                {
                    question: "Welche Vermutungen über die Ursache des Fehlers können Sie anhand des Dokuments machen?",
                    answers: [{
                            correct: false,
                            text: "Clara hat Probleme beim Darstellungswechsel.",
                            //additionalExplanation: "",
                            response: `Darüber, inwiefern Clara der Darstellungswechsel gelingt, kann man anhand der vorliegenden Aufgaben keine Aussage treffen, da kein Darstellungswechsel erforderlich ist.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Clara hat das Prinzip des Stellenwerts und das Prinzip des Bündelns noch nicht verstanden.",
                            response: `Das glauben wir auch. Da Clara zwei Ziffern zur Besetzung eines Stellenwerts aufschreibt, scheint sie das Prinzip des Stellenwerts noch nicht verstanden zu haben. Dies deutet auch auf Probleme mit dem Bündelungsprinzip hin, denn Clara schreibt zwei Ziffern an eine Stelle, da sie keine Bündelung durchführt bzw. Elemente der gleichen Bündelungseinheit nicht zusammenfasst. Sie rechnet 9 E + 6 E = 15 E. Die 15 E werden jedoch nicht als 1 Z und 5 E erkannt bzw. der 1 Z nicht mit den beiden anderen 2 Z aus der Rechnung 2 Z + 0 Z = 2 Z zusammengefasst, vgl. Entscheidungsstufe 1.`,
                        },
                        {
                            correct: false,
                            text: "Clara hat Probleme mit der inversen Sprechweise von Zahlen.",
                            response: `Das halten wir für unwahrscheinlich. In dem vorliegenden Dokumenten zeigen sich keine Hinweise darauf, dass Clara aufgrund der inversen Sprechweise Probleme beim Schreiben oder Verstehen von Zahlen hat. Bei der ersten möglichen Lösungsweise, die wir Ihnen in der ersten Frage vorschlagen haben, sehen Sie, wie sich ein solches Problem möglicherweise äußern könnte.`,
                        },
                    ]
                }
            ],
        ]
    }

    /*
    *   all correct response
   Genau, Clara kann ein und zweistellige Zahlen miteinander addieren, wenn keine Bündelung erforderlich ist.Allerdings hat sie noch Probleme beim Verständnis von Stellenwerten.Außerdem hat sie das Prinzip der Bündelung vermutlich noch nicht verstanden und kann es dementsprechend nicht anwenden.
    */

    return example;
}

export default createObject();