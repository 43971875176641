<template>
  <div>
    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <h2 id="diagnose">Diagnose</h2>
    <p>
      Nachdem Du Dir das Hintergrundwissen erarbeitet hast, baut nun der
      Diagnoseteil darauf auf:
    </p>
    <p>
      Ein Indikator für eine gute "Zahlvorstellung" ist es, wenn eine Person in
      der Lage ist, sicher zwischen dem notierten Zahlzeichen einer Zahl, dem
      dazugehörigen Zahlwort und der entsprechenden Menge mental hin und her zu
      übersetzen, also die Fähigkeit zum Darstellungswechsel besitzt.
    </p>
    <p>
      Ausschlaggebend für ein tragfähiges Stellenwertverständnis ist jedoch
      nicht nur diese Fähigkeit alleine, sondern auch ein Metawissen über die
      Durchführung der Übersetzungstätigkeiten. Das bedeutet, dass die Person
      weiß und erklären kann, wie die einzelnen Ziffern des Zahlzeichens, die
      Bestandteile des Zahlwortes und die Anzahlen der einzelnen
      Bündelungseinheiten zusammenhängen (vgl. Schulz 2014, S. 150).
    </p>

    <p>
      Bei den folgenden Aufgaben wird ein Darstellungswechsel von einer
      bildlichen Darstellung in die Stellenwerttafel und in die Zahlschreibweise
      verlangt. Betrachte die Kinderdokumente und gruppiere sie nach ähnlichen
      Vorgehensweisen oder Fehlern.
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[0].id"
      :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[0].showGroupNames"
      :workOrder="LBSTGruppen[0].workOrder"
      :possibleCategories="LBSTGruppen[0].possibleCategories"
      :elements="LBSTGruppen[0].elements"
      :responses="LBSTGruppen[0].responses"
    />

    <p>
      Insbesondere beim Darstellungswechsel wird oft sichtbar, inwiefern die
      Prinzipien des Bündelns, des Stellenwerts und des Zahlwerts verstanden und
      entsprechend berücksichtigt werden. Das gilt besonders dann, wenn die
      Darstellungen der Zahlen nicht den Konventionen entsprechen. Ein Beispiel
      dafür kann eine bildliche Darstellung von 13 ungebündelten Zehnern sein.
      Insbesondere Aufgaben, in denen ein Darstellungswechsel erforderlich ist
      und kleine Irritationen eingebaut sind, sind geeignet um zu
      diagnostizieren, inwiefern Kinder Prinzipien unseres Stellenwertsystems
      verstanden haben und berücksichtigen können.
    </p>

    <p>
      An bearbeiteten Dokumenten von Lernenden wird deutlich, inwiefern durch
      Darstellungswechsel Probleme im Verständnis der Schülerinnen und Schüler
      aufgedeckt werden können. Es ist jedoch anzumerken, dass nicht jede
      Aufgabe, in der ein Darstellungswechsel gefordert ist, zur Diagnose jedes
      der für das Stellenwertverständnis relevanten Prinzipien geeignet ist. Mit
      einer Aufgabe, in der keine Bündelung erforderlich ist, wirst Du auch
      nichts darüber erfahren, inwiefern ein Kind das Bündelungsprinzip bereits
      berücksichtigt und somit vermutlich auch verstanden hat. Ebenso wirst Du
      keine Informationen dazu erhalten, ob ein Kind die Rolle der Null in einem
      Stellenwertsystem versteht, wenn in der Aufgabe kein Stellenwert vorkommt,
      der nicht besetzt ist. Insofern ist die Wahl der Aufgaben für eine
      Diagnose im Hinblick darauf gut abzuwägen, was man erfahren möchte und was
      die gestellten Aufgaben überhaupt für Erkenntnisse liefern können.
    </p>

    <p>
      Bisher lag der Fokus auf der Identifikation typischer Fehler. Im Sinne
      einer förderorientierten Diagnose ist es jedoch unabdingbar, die Ursache
      solcher Fehler näher zu bestimmen. Nur wenn man die Ursache eines Fehlers
      kennt, kann man passende Fördermaßnahmen ergreifen, um die
      Fehlvorstellungen der Kinder aufzuarbeiten.
    </p>

    <LernbausteinStempeln
      :id="LBSTStempeln[0].id"
      :workOrder="LBSTStempeln[0].workOrder"
      :image="LBSTStempeln[0].image"
      :audio="LBSTStempeln[0].audio"
      :elements="LBSTStempeln[0].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[1].id"
      :workOrder="LBSTStempeln[1].workOrder"
      :image="LBSTStempeln[1].image"
      :audio="LBSTStempeln[1].audio"
      :elements="LBSTStempeln[1].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[2].id"
      :workOrder="LBSTStempeln[2].workOrder"
      :image="LBSTStempeln[2].image"
      :audio="LBSTStempeln[2].audio"
      :elements="LBSTStempeln[2].elements"
    />

    <p>
      Die Ursachen von Fehlern können sehr vielfältig sein. Das Erkennen dieser
      Ursachen für Probleme eines Lernenden, bildet die Grundlage für eine
      individuelle und adaptive Förderung. Neben dem Wahrnehmen der Probleme von
      Lernenden kommt vor allem der Ursachenforschung eine enorme Wichtigkeit
      zu. Nur wenn man die Ursachen, das heißt die Unklarheiten und
      Fehlvorstellungen im Verständnis der Lernenden kennt, können diese
      aufgearbeitet und durch ein besseres Verständnis abgebaut werden.
    </p>
    <br />

    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <Survey
      assignedSite="swve"
      broderTopic="swv"
      :topics="[
        'Bündeln und Entbündeln',
        'Stellenwert und Zahlenwert',
        'Darstellungswechsel beim Stellenwertverständnis',
        'Stellenwertverständnis allgemein',
      ]"
      :alreadySend="
        $store.state.user.completedSurveys
          ? $store.state.user.completedSurveys.includes('swve')
          : false
      "
      :skipLearningStyle="$store.state.user.completedSurveys.length > 0"
    />

    <br/>
    <br/>
    <br/>
    <h4 id="literaturverzeichnis">Literaturverzeichnis</h4>
      <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              Schulz, A. (2014). <i>Fachdidaktisches Wissen von Grundschullehrkräften. Diagnose und Förderung bei besonderen Problemen beim Rechnenlernen.</i> Wiesbaden: Springer Sprektrum.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Survey from "@/components/faledia/Survey";
import AppHelper from "@/common/AppHelper";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_Gruppendiagnose from "./beispiele/Gruppendiagnose";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import Einzeldiagnose_Clara from "./beispiele/Einzeldiagnose_Clara";
import Einzeldiagnose_Marie from "./beispiele/Einzeldiagnose_Marie";
import Einzeldiagnose_Taifun from "./beispiele/Einzeldiagnose_Taifun";

export default {
  beforeCreate: function() {
    AppHelper.isUserAllowedRedirectIfNot("swve");
  },
  components: {
    Survey,
    LernbausteinGruppen,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_Gruppendiagnose],
    LBSTStempeln: [
      Einzeldiagnose_Clara,
      Einzeldiagnose_Marie,
      Einzeldiagnose_Taifun,
    ],
  }),
  methods: {
    navigateToHintergrundwissen: function() {
      this.$router.push({ name: "swve" });
    },
  },
};
</script>

<style scoped>
.imageTableImages {
  margin-top: 40px;
}
.imageTable {
  margin-bottom: 20px;
}
</style>
