// "C:\Users\Andreas\Nextcloud\faledia.all.project\Lernbausteine\SWV\1 Hintergrund\3 Darstellungswechsel\Gruppen_Aktivität 1_10er-System\Vorlage_Lernbaustein_Gruppen_Darstellungswechsel_10erSystem_200917.pptx"
function createObject() {
  let example = {
    id: "Gruppendiagnose",
    showGroupNames: false,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder: `
        Sie sehen hier 12 Schüler:innendokumente. In jeweils drei Dokumenten zeigen die Kinder eine ähnliche Vorgehensweise bzw. begehen einen ähnlichen Fehler. Fassen Sie jeweils drei zusammengehörige Dokumente in einer Gruppe zusammen. 
            `,
    possibleCategories: [
      {
        id: 1,
        name: "Probleme mit der Null",
      },
      {
        id: 2,
        name: "Fehlende Bündelung",
      },
      {
        id: 3,
        name: "Falsche Bündelungseinheit",
      },
      {
        id: 4,
        name: "Falsche Reihenfolge der Stellenwerte",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok1.jpg"),
        category: 2,
        flex: 4,
      },
      {
        id: 2,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok1.jpg"),
        category: 4,
        flex: 4,
      },
      {
        id: 3,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok3.jpg"),
        category: 2,
        flex: 4,
      },
      {
        id: 4,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok1.jpg"),
        category: 1,
        flex: 4,
      },
      {
        id: 5,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok1.jpg"),
        category: 3,
        flex: 4,
      },
      {
        id: 6,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok3.jpg"),
        category: 1,
        flex: 4,
      },
      {
        id: 7,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok3.jpg"),
        category: 3,
        flex: 4,
      },
      {
        id: 8,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp2_Dok2.jpg"),
        category: 2,
        flex: 4,
      },
      {
        id: 9,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok2.jpg"),
        category: 4,
        flex: 4,
      },
      {
        id: 10,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp1_Dok2.jpg"),
        category: 1,
        flex: 4,
      },
      {
        id: 11,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp3_Dok2.jpg"),
        category: 3,
        flex: 4,
      },
      {
        id: 12,
        image: require("@/assets/swve/Gruppendiagnose/Fehlertyp4_Dok3.jpg"),
        category: 4,
        flex: 4,
      },
    ],
  };

  const varzweigleich = `In zwei Dokumenten zeigt sich eine ähnlicher Lösungsweg. Eines der Dokumente passt jedoch nicht dazu. Versuchen Sie, das nicht passende Dokument zu finden. Die folgenden Fragen können Ihnen helfen: `;

  const varFrage1 = `Was passiert, wenn ein Stellenwert über keine Elemente verfügt? `;

  const varFrage2 = `Was passiert, wenn ein Stellenwert über mehr als 9 Elemente verfügt? `;

  const varFrage3 = `Welche Bündelungseinheiten benutzt das Kind? `;

  const varFrage4 = `Wird die Anzahl der Elemente eines Stellenwerts, die sich in der bildlichen Darstellung zeigen, in der Stellenwerttafel und in der Zahl jeweils mit dem richtigen Stellenwert verknüpft? `;

  const varerneut = `Welches Dokument passt nicht? Versuchen Sie es erneut.`;

  const vardreiunterschiedlich = `Upps. Das ist noch sehr durcheinander. Versuchen Sie die Auffälligkeiten in den Dokumenten näher zu charakterisieren und weitere Dokumente zu finden, bei denen ein ähnliches Vorgehen zu erkennen ist. Die folgenden Fragen können Ihnen helfen:`;

  const varerneut2 = `Versuchen Sie es erneut!`;

  example.responses = [
    {
      id: "0003",
      category: example.possibleCategories[3], // Array Index not id
      text:
        "Korrekt! In allen drei Dokumenten werden die bildlichen Darstellungen der Stellenwerte falsch interpretiert. Die Mächtigkeiten der dargestellten Stellenwerte werden sowohl in der Stellenwerttafel als auch in der Zahl entsprechend der im Bild vorgelegten Reihfolge notiert und nicht an die Stelle, die für den entsprechenden Stellenwert vorgesehen ist geschrieben (Anzahl der Elemente ganz rechts an Einerstelle, usw.).",
    },
    {
      id: "0012",
      text: varzweigleich + varFrage3 + varFrage4 + varerneut,
    },
    {
      id: "0021",
      text: varzweigleich + varFrage3 + varFrage4 + varerneut,
    },
    {
      id: "0030",
      category: example.possibleCategories[2], // Array Index not id
      text:
        "Prima. In allen drei Dokumenten wird eine falsche Bündelungseinheit benutzt (ein Fünfer wird als ein Zehner interpretiert). Der Zahlwert der Fünfer wird an der Zehnerstelle notiert. Falls Zehner vorhanden sind, werden die Zehner (da die Zehnerstelle bereits besetzt ist) an der Hunderterstelle notiert. Alle weiteren Einheiten (falls vorhanden) werden somit eine Spalte weiter links notiert. In der Ziffernschreibweise der Zahl verwenden diese Kinder sozusagen auch eine weitere Stelle für die Fünfer.",
    },
    {
      id: "0102",
      text: varzweigleich + varFrage2 + varFrage4 + varerneut,
    },
    {
      id: "0111",
      text:
        vardreiunterschiedlich + varFrage2 + varFrage3 + varFrage4 + varerneut2,
    },
    {
      id: "0120",
      text: varzweigleich + varFrage2 + varFrage3 + varerneut,
    },
    {
      id: "0201",
      text: varzweigleich + varFrage2 + varFrage4 + varerneut,
    },
    {
      id: "0210",
      text: varzweigleich + varFrage2 + varFrage3 + varerneut,
    },
    {
      id: "0300",
      category: example.possibleCategories[1], // Array Index not id
      text:
        "Sehr gut. In allen drei Dokumenten führen die Kinder keine Bündelung durch, wenn an einem Stellenwert 9 oder mehr Elemente vorhanden sind. Die beiden Ziffern der zweistelligen Mächtigkeit eines Stellenwerts werden in der entsprechenden Spalte der Stellenwerttafel eingetragen und in der Zifferndarstellung der Zahl hintereinander aufgeschrieben (quasi zwei Ziffern an eine Stelle).",
    },
    {
      id: "1002",
      text: varzweigleich + varFrage1 + varFrage4 + varerneut,
    },
    {
      id: "1011",
      text:
        vardreiunterschiedlich + varFrage1 + varFrage3 + varFrage4 + varerneut2,
    },
    {
      id: "1020",
      text: varzweigleich + varFrage1 + varFrage3 + varerneut,
    },
    {
      id: "1101",
      text:
        vardreiunterschiedlich + varFrage1 + varFrage2 + varFrage4 + varerneut2,
    },
    {
      id: "1110",
      text:
        vardreiunterschiedlich + varFrage1 + varFrage2 + varFrage3 + varerneut2,
    },
    {
      id: "1200",
      text: varzweigleich + varFrage1 + varFrage2 + varerneut,
    },
    {
      id: "2001",
      text: varzweigleich + varFrage1 + varFrage4 + varerneut,
    },
    {
      id: "2010",
      text: varzweigleich + varFrage1 + varFrage3 + varerneut,
    },
    {
      id: "2100",
      text: varzweigleich + varFrage1 + varFrage2 + varerneut,
    },
    {
      id: "3000",
      category: example.possibleCategories[0], // Array Index not id
      text:
        "Super. In allen drei Dokumenten notieren die Kinder in der Zahl keine Null für die Stellenwerte, bei denen keine Elemente vorhanden sind, noch tragen sie eine Null in der entsprechenden Spalte der Stellenwerttafel ein.",
    },
  ];

  return example;
}

export default createObject();
